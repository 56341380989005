import React, { useEffect, useState } from "react";
import map from "../images/jordan-map.png";
import hur from "../images/24hu.png";
import safe from "../images/safe.png";
import search from "../images/search.png";
import candel from "../images/Candels2.png";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import nam from "../images/footerphoto.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Controller, useForm } from "react-hook-form";
import { apiCall } from "../apis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ar from "react-phone-input-2/lang/es.json";

export const Footer = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, watch, setValue } = useForm();
  const selectedState = watch("state");
  const [countriesData, setCountriesData] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchCountries = async () => {
    const endPoint = '/common/countries';

    try {
      const response = await apiCall.get(endPoint);
      if (response.status) {
        setCountriesData(response.result);
      }
    } catch (error) {
      console.warn(error.response.error);
    }
  };

  useEffect(() => {
    if (selectedState) {
      const selectedCountry = countriesData.find(
        (item) => item.countryEn === selectedState,
      );

      if (selectedCountry) {
        console.log(selectedCountry);
        setValue('phone_no', `${selectedCountry.phoneCode}`);
      }
    }
  }, [selectedState, countriesData, setValue]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const onSubmit = (result) => {
    if (result.password === result.confirm_password) {
      setLoading(true);
      // const emailData = {
      //   email: result.email,
      // };
      // const phone_no_data = {
      //   phone: `+${result.phone_no}`,
      // };
      const register_data = {
        // type: "individual",
        firstName: result.firstName,
        lastName: result.lastName,
        countryResidency: result.state,
        phone: `+${result.phone_no}`,
        email: result.email,
        portalPassword: result.password,
        confirmPassword: result.confirm_password,
        // source: 'Website Live - Jordan',
        // emailPin: "2344",
        // tos: true,
        utmCampaign: 'JORDANCAMPAIGN',
        // checkPin: result.marketingConsent,
        language: 'en-gb',
        declarations: [
          'By clicking here I give my consent for Accuindex to contact me for marketing purposes. You can opt out at any time. For further details please see ourMarketing and Communication Policy Statement.',
        ],
        // countryCode: fetchCountryCode[0].countryEn,
      };
      apiCall
        .post('/cp/gate/register/live', register_data)
        .then(() => {
          setLoading(false);
          navigate('/success');
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message, {
            autoClose: 2000,
          });
        });
    } else {
      toast.warn('Password and Confirm Password must be the same. ', {
        autoClose: 2000, // 2000 milliseconds = 2 seconds
      });
    }
  };

  const onInvalidDetails = (errors) => {
    Object.values(errors).forEach((error) => {
      toast.error(error.message, {
        autoClose: 2000, // 2000 milliseconds = 2 seconds
      });
    });
  };

  return (
    <>
      <div
        className="relative"
        style={{
          background: `linear-gradient(
          to bottom,
          #9A2168 0%,
          #9A2168 63%,
          #E0E0E0 50%,
          #E0E0E0 100%
        )`,
        }}
      >
        <img
          src={map}
          alt="Map"
          className="absolute inset-x-0 mx-auto top-64 -translate-y-1/2 h-[25%] md:h-[45%] md:top-96 lg:h-[55%] lg:top-96 w-auto"
        />
        <p
          className="lg:text-5xl md:text-4xl font-bold text-[28px] text-white text-center pt-24"
          style={{
            fontFamily: 'Tajawal, sans-serif',
          }}
        >
          AccuIndex ضع ثقتك في
        </p>
        <div className="container px-16 lg:px-20  lg:py-4 py-2 mx-auto">
          <div className="flex flex-col-reverse md:flex-row items-center justify-center">
            <div className="lg:w-1/4 md:w-1/3 w-full mb-6">
              <div className="h-full text-center">
                <img
                  src={hur}
                  alt=""
                  className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-6 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
                />
                <p
                  className="leading-relaxed text-white"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '800',
                  }}
                >
                  خدمة عملاء 24/7
                </p>
                <p
                  className="text-[#D2ADC3] xl:px-12 lg:px-4"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '400',
                  }}
                >
                  فريقنا هنا لدعمك على مدار الساعة طوال أيام الأسبوع
                </p>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-6 p-4">
              <div className="h-full text-center">
                <img
                  src={safe}
                  alt=""
                  className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-6 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
                />
                <p
                  className="leading-relaxed text-white"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '800',
                  }}
                >
                  حماية
                </p>
                <p
                  className="text-[#D2ADC3] xl:px-12 lg:px-4"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '400',
                  }}
                >
                  نحن نضمن لك أعلى مستويات الحماية لأموالك وبياناتك
                </p>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 w-full mb-6 p-4">
              <div className="h-full text-center">
                <img
                  src={search}
                  alt=""
                  className="lg:w-16 lg:h-16 w-12 h-12 lg:mb-6 mb-2 object-cover object-center inline-block border-2 border-gray-200 bg-gray-100"
                />
                <p
                  className="leading-relaxed text-white"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '800',
                  }}
                >
                  شفافية
                </p>
                <p
                  className="text-[#D2ADC3] xl:px-12 lg:px-4"
                  style={{
                    fontFamily: 'Tajawal, system-ui',
                    fontWeight: '400',
                  }}
                >
                  كل تعاملاتنا تتم بوضوح وشفافية، حتى يمكنك التداول بثقة
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="text-gray-600 mb-14 mx-4 relative">
          <div
            className="flex justify-center items-center"
            style={{
              backgroundImage: `url(${candel})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center -70px',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              className="lg:w-2/3 bg-[#E0E0E0] rounded-b-lg p-5 lg:px-16 md:px-24 md:py-12 flex flex-col w-full relative z-10 shadow-md bg-center  bg-no-repeat xl:bg-cover"
              style={{ backgroundImage: `url(${candel})` }}
              id="box"
            >
              <h2
                className="text-[#9A2168] text-center mb-1 text-2xl title-font"
                style={{ fontFamily: 'Tajawal, system-ui', fontWeight: 800 }}
              >
                ابدأ رحلتك في التداول الآن
              </h2>

              <form onSubmit={handleSubmit(onSubmit, onInvalidDetails)}>
                <div className="sm:flex flex-row-reverse sm:space-x-4 justify-between items-center relative mb-4">
                  <div className="w-full flex flex-col items-end md:ml-2 pb-4 md:pb-0">
                    <label
                      htmlFor="firstName"
                      className="leading-7 text-[18px] text-gray-600 text-right"
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      الاسم الأول
                    </label>
                    <input
                      {...register('firstName', {
                        required: 'First name is required',
                      })}
                      dir="rtl"
                      type="text"
                      name="firstName"
                      className="w-full bg-[#EAEAEA] rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="w-full flex flex-col items-end ">
                    <label
                      htmlFor="lastName"
                      className="leading-7 text-[18px] text-gray-600 text-right "
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      اسم العائلة
                    </label>
                    <input
                      {...register('lastName', {
                        required: 'Last name is required',
                      })}
                      dir="rtl"
                      type="text"
                      name="lastName"
                      className="w-full bg-[#EAEAEA] rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2  px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>

                <div className="relative mb-4 flex flex-col items-end md:ml-4">
                  <label
                    htmlFor="email"
                    className="leading-7 text-[18px] text-gray-600 text-right "
                    style={{
                      fontFamily: 'Tajawal, system-ui',
                      fontWeight: '500',
                    }}
                  >
                    البريد الإلكتروني
                  </label>
                  <input
                    {...register('email', { required: 'Email is required' })}
                    dir="rtl"
                    type="email"
                    name="email"
                    className="w-full bg-[#EAEAEA] rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2  px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>

                <div className="sm:flex flex-row-reverse sm:space-x-4 justify-center items-center relative mb-4">
                  <div className="w-full flex flex-col items-end md:ml-2 pb-4 md:pb-0">
                    <label
                      htmlFor="state"
                      className="leading-7 text-[18px] text-gray-600 text-right "
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      الدولة
                    </label>
                    <select
                      {...register('state', { required: 'State is required' })}
                      dir="rtl"
                      name="state"
                      className="w-full bg-[#EAEAEA] rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-[15px]  p-2 leading-8 transition-colors duration-200 ease-in-out"
                    >
                      <option value="">لا شيء</option>
                      {countriesData?.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="w-full flex flex-col">
                    <p
                      className="leading-7 text-[18px] text-gray-600 text-right "
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      رقم الهاتف
                    </p>
                    <Controller
                      name="phone_no"
                      control={control}
                      rules={{ required: 'Phone number is required' }}
                      defaultValue="" // Ensure this is empty initially
                      render={({ field }) => (
                        <PhoneInput
                          country={'us'}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          localization={ar}
                          inputStyle={{
                            width: '100%',
                            height: '53px',
                            backgroundColor: '#EAEAEA',
                          }}
                          dropdownStyle={{
                            width: 250,
                          }}
                          buttonStyle={{
                            backgroundColor: '#EAEAEA',
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="sm:flex flex-row-reverse sm:space-x-4 justify-center items-center relative mb-4">
                  <div className="w-full flex flex-col items-end  md:ml-2 pb-4 md:pb-0">
                    <label
                      htmlFor="password"
                      className="leading-7 text-[18px] text-gray-600 text-right "
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      كلمة مرور البوابة
                    </label>
                    <input
                      {...register('password', {
                        required: 'Password is required.',
                      })}
                      dir="rtl"
                      type="password"
                      name="password"
                      className="w-full bg-[#EAEAEA] rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                  <div className="w-full flex flex-col items-end">
                    <label
                      htmlFor="confirm_password"
                      className="leading-7 text-[18px] text-gray-600 text-right "
                      style={{
                        fontFamily: 'Tajawal, system-ui',
                        fontWeight: '500',
                      }}
                    >
                      تأكيد كلمة المرور للبوابة
                    </label>
                    <input
                      {...register('confirm_password', {
                        required: 'Confirm Password is required.',
                      })}
                      dir="rtl"
                      type="password"
                      name="confirm_password"
                      className="w-full rounded border focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2  px-3 leading-8 transition-colors duration-200 ease-in-out bg-[#EAEAEA]"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-end text-end mb-8">
                    <p dir="rtl" className="text-right">
                      بالنقر هنا، أوافق على أن تقوم شركة{' '}
                      <span className="text-[#9A2168] font-bold">
                        Accuindex
                      </span>{' '}
                      بالاتصال بي لأغراض التسويق. يمكنك إلغاء الاشتراك في أي
                      وقت.لمزيد من التفاصيل
                      <span className="text-[#9A2168]">
                        {' '}
                        يرجى الاطلاع على{' '}
                        <span className="font-bold">
                          سياسة التسويق والاتصالات{' '}
                        </span>{' '}
                        الخاص بنا
                      </span>
                    </p>
                    <label className="relative cursor-pointer ml-2 mt-1">
                      <input
                        {...register('marketingConsent')}
                        type="checkbox"
                        className="sr-only peer"
                      />
                      <div className="w-5 h-5 border-2 border-[#9A2168] rounded-md peer-checked:bg-[#9A2168] peer-checked:border-bg-[#9A2168] flex justify-center items-center">
                        <FaCheck size={14} color="#E0E0E0" />
                      </div>
                    </label>
                  </div>
                </div>

                <button
                  disabled={loading}
                  type="submit"
                  className="text-white bg-[#9A2168] border-0 py-2 px-6 focus:outline-none hover:bg-[#811e58] rounded text-lg w-full"
                >
                  {loading ? 'loading...' : ' سجل الآن'}
                </button>
              </form>
            </div>
          </div>
        </section>

        <div
          className="pb-8 px-8 items-center justify-center lg:mx-12 xl:mx-32 2xl:mx-64"
          style={{ fontFamily: 'Tajawal, system-ui', fontWeight: '400' }}
        >
          <div className=" items-center justify-center md:text-end text-center md:flex md:flex-row-reverse">
            <p dir="rtl" className="text-center inline-block">
              <img
                src={nam}
                alt=""
                className=" pb-4 md:w-56 md:pb-1 md:pl-2 inline-block"
              />
              تحذير المخاطر: قبل البدء في التداول؛ ينبغي عليك إدراك المخاطر
              المترتبة على ذلك، والأخذ بعين الاعتبار مستوى خبرتك في هذا المجال.
              وعلاوة على ذلك؛ فإن التداول والاستثمار في الأسواق العالمية
              والبورصات الأجنبية ينطويان على درجة عالية من المخاطر وقد يتسببان
              في خسائر كبيرة، لذلك فإنهما قد لا يكونان خيارين مناسبين لجميع
              الأفراد
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#E0E0E0] text-center ">
        <hr className="border-t border-gray-400 w-full " />
        <p className="text-[#14192E] opacity-[0.75] p-4 ">
          © 2024 Accuindex. كل الحقوق محفوظة
        </p>
      </div>
    </>
  );
};
